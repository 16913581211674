// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en.json';
import translationUK from './uk.json';
import translationAR from './ar.json';
import translationDE from './de.json';
import translationES from './es.json';
import translationFR from './fr.json';
import translationHI from './hi.json';
import translationID from './id.json';
import translationIT from './it.json';
import translationJA from './ja.json';
import translationPT from './pt.json';
import translationRU from './ru.json';
import translationZH from './zh.json';

export const resources = {
    en: { translation: translationEN },
    uk: { translation: translationUK },
    ar: { translation: translationAR },
    de: { translation: translationDE },
    es: { translation: translationES },
    fr: { translation: translationFR },
    hi: { translation: translationHI },
    id: { translation: translationID },
    it: { translation: translationIT },
    ja: { translation: translationJA },
    pt: { translation: translationPT },
    ru: { translation: translationRU },
    zh: { translation: translationZH },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',

    interpolation: {
        escapeValue: false
    }
});


export default i18n;

//Here are the language codes (ISO 639-1) for the languages you listed:
//
// - English: **en**
// - Hindi: **hi**
// - Spanish: **es**
// - Portuguese: **pt**
// - Russian: **ru**
// - Ukrainian: **uk**
// - Japanese: **ja**
// - Chinese: **zh**
// - German: **de**
// - Italian: **it**
// - French: **fr**
// - Arabic: **ar**
// - Indonesian: **id**