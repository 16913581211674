import en from "../locales/icons/en.svg";
import uk from "../locales/icons/uk.svg";
import ar from "../locales/icons/ar.svg";
import de from "../locales/icons/de.svg";
import es from "../locales/icons/es.svg";
import fr from "../locales/icons/fr.svg";
import hi from "../locales/icons/hi.svg";
import id from "../locales/icons/id.svg";
import it from "../locales/icons/it.svg";
import ja from "../locales/icons/ja.svg";
import pt from "../locales/icons/pt.svg";
import ru from "../locales/icons/ru.svg";
import zh from "../locales/icons/zh.svg";
import {Trans, useTranslation} from "react-i18next";
import Example from "../locales/example";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Menu from "../components/menu";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

function Id({links}) {
    const YOUTUBE_API_KEY = 'AIzaSyAHpzFA_2zvoejbxNiiB_qQaPMwdljjcS4';
    const RAPIDAPI_KEY = '66ae7dd596msha4e88745aaa94bap1a328cjsn7d924642bacb';

    const [query, setQuery] = useState('');
    const [videos, setVideos] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [nextPageToken, setNextPageToken] = useState('');
    const [searchMode, setSearchMode] = useState('keyword');
    const [errorMessage, setErrorMessage] = useState('');
    const {t, i18n} = useTranslation()
    const [menu, setMenu] = useState(false)
    const [icon, setIcon] = useState(id);
    const [link, setLink] = useState('')

    const openLangMenu = () => {
        setMenu(!menu)
    }

    const parseDuration = (duration) => {
        const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
        return [
            match[1] ? match[1].slice(0, -1) + 'h ' : '',
            match[2] ? match[2].slice(0, -1) + 'm ' : '',
            match[3] ? match[3].slice(0, -1) + 's' : '',
        ].join('');
    };

    const decodeHtml = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    const isValidYouTubeUrl = (url) => {
        const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/;
        return youtubeRegex.test(url);
    };
    const extractVideoId = (url) => {
        const standardYouTubeRegex = /(?:\?v=|&v=|youtu\.be\/|\/embed\/|\/v\/|watch\?v=)([^#&?]*)(?:\S+)?/;
        const match = url.match(standardYouTubeRegex);
        return match ? match[1] : null;
    };
    const handleSearch = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Log the current query (URL or keyword)
        console.log("Current query: ", query);

        if (searchMode === 'url' && !isValidYouTubeUrl(query)) {
            setErrorMessage('Invalid YouTube URL');
            console.log("Invalid URL.");
            return;
        }

        setLoadingSearch(true);
        try {
            let videoId;
            if (searchMode === 'url') {
                videoId = extractVideoId(query);
                console.log("Extracted video ID: ", videoId); // Log the extracted video ID

                if (!videoId) {
                    setErrorMessage('Invalid YouTube URL or video ID not found.');
                    console.log("Video ID not found in the URL.");
                    setLoadingSearch(false);
                    return;
                }

                // Fetch video details by ID
                const response = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
                    params: {
                        part: 'snippet,contentDetails',
                        id: videoId,
                        key: YOUTUBE_API_KEY,
                    },
                });

                // Log the response from YouTube API
                console.log("YouTube API response (video details): ", response.data);

                const videoData = response.data.items[0]; // Only one video will be returned
                if (!videoData) {
                    setErrorMessage('Video not found');
                    setLoadingSearch(false);
                    console.log("No video data found for the ID.");
                    return;
                }

                const videoWithDetails = {
                    ...videoData,
                    duration: parseDuration(videoData.contentDetails.duration),
                };

                // Log the video with details
                console.log("Video with details: ", videoWithDetails);

                setVideos([videoWithDetails]); // Store as an array with 1 video
            } else {
                // Handle search by keyword
                const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
                    params: {
                        part: 'snippet',
                        maxResults: 10,
                        q: query,
                        key: YOUTUBE_API_KEY,
                    },
                });

                // Log the response from YouTube API
                console.log("YouTube API response (search results): ", response.data);

                const validVideos = response.data.items.filter(item => item.id.videoId);
                const videoIds = validVideos.map(video => video.id.videoId).join(',');

                const videoDetailsResponse = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
                    params: {
                        part: 'contentDetails',
                        id: videoIds,
                        key: YOUTUBE_API_KEY,
                    },
                });

                const videosWithDetails = validVideos.map(video => {
                    const details = videoDetailsResponse.data.items.find(item => item.id === video.id.videoId);
                    return {
                        ...video,
                        duration: details ? parseDuration(details.contentDetails.duration) : null
                    };
                });

                console.log("Videos with details: ", videosWithDetails);

                setVideos(videosWithDetails);
            }
        } catch (error) {
            console.error('Error fetching data from YouTube API', error);
            setErrorMessage('Error fetching data. Please try again.');
        } finally {
            setLoadingSearch(false);
        }
    };


    const handleLoadMore = async () => {
        setLoadingSearch(true);
        try {
            const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
                params: {
                    part: 'snippet',
                    maxResults: 10,
                    q: query,
                    pageToken: nextPageToken,
                    key: YOUTUBE_API_KEY,
                },
            });
            const validVideos = response.data.items.filter(item => item.id.videoId);
            const videoIds = validVideos.map(video => video.id.videoId).join(',');
            const videoDetailsResponse = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
                params: {
                    part: 'contentDetails',
                    id: videoIds,
                    key: YOUTUBE_API_KEY,
                },
            });
            const videosWithDetails = validVideos.map(video => {
                const details = videoDetailsResponse.data.items.find(item => item.id === video.id.videoId);
                return {
                    ...video,
                    duration: details ? parseDuration(details.contentDetails.duration) : null
                };
            });
            setVideos([...videos, ...videosWithDetails]);
            setNextPageToken(response.data.nextPageToken);
        } catch (error) {
            console.error('Error fetching more data from YouTube API', error);
        } finally {
            setLoadingSearch(false);
        }
    };

    const handleDownload = async (videoId) => {
        console.log('Attempting to download video with ID:', videoId);
        setCurrentVideo(videoId);
        setLoadingDownload(true);

        const fetchDownloadLink = async (videoId) => {
            const options = {
                method: 'GET',
                url: `https://youtube-mp36.p.rapidapi.com/dl`,
                params: {id: videoId},
                headers: {
                    'x-rapidapi-key': RAPIDAPI_KEY,
                    'x-rapidapi-host': 'youtube-mp36.p.rapidapi.com'
                }
            };

            try {
                const response = await axios.request(options);
                if (response.data.status === 'processing') {
                    setTimeout(() => fetchDownloadLink(videoId), 1000);
                } else if (response.data.link) {
                    const a = document.createElement('a');
                    a.href = response.data.link;
                    a.download = `${videoId}.mp3`;
                    a.click();
                }
            } catch (error) {
                console.error('Error downloading the MP3 for video ID:', videoId, error);
            } finally {
                setLoadingDownload(false);
                setCurrentVideo(null);
            }
        };

        await fetchDownloadLink(videoId);
    };

    const handleModeSwitch = (mode) => {
        setSearchMode(mode);
        setVideos([]);
        setErrorMessage('');
    };

    const getRandomActiveLink = () => {
        const randomIndex = Math.floor(Math.random() * links.length);
        console.log(links[randomIndex])
        return `${links[randomIndex]}` || "/sitemap";
    };

    useEffect(() => {
        setLink(getRandomActiveLink)
        i18n.changeLanguage('id')
    }, [])

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const location = useLocation();
    useEffect(() => {
        document.documentElement.lang = 'id';
    }, [location]);

    return (
        <>
            <Helmet>
                <title>Konverter YouTube ke MP3 - YouTubeMP3.co</title>
                <meta name="description"
                      content="Konversi video YouTube ke MP3 dengan mudah menggunakan konverter gratis dan cepat kami. Penggunaan tanpa batas, tanpa iklan - coba sekarang!"/>
            </Helmet>
            <div className="app">
                <header>
                    <div>
                        <a href='/' className='logo_a'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M23.5931 0.278392C23.335 0.0578455 22.9939 -0.0388108 22.6575 0.0142517L7.94789 2.33681C7.38352 2.42592 6.96778 2.91244 6.96778 3.48385V8.12902V15.4838H4.64517C2.08383 15.4839 0 17.394 0 19.7419C0 22.0898 2.08383 24 4.64517 24C7.20652 24 9.29034 22.0898 9.29034 19.7419V16.6451V9.12131L21.6774 7.16545V13.5484H19.3549C16.7935 13.5484 14.7097 15.4585 14.7097 17.8065C14.7097 20.1544 16.7935 22.0645 19.3549 22.0645C21.9162 22.0645 24 20.1543 24 17.8065V14.7097V5.80645V1.16128C24 0.821674 23.8513 0.499033 23.5931 0.278392Z"
                                    fill="#FF784B"/>
                            </svg>
                            <h1>YouTube MP3</h1>
                        </a>
                    </div>
                    <nav>
                        <a href='#convert'>{t("header.convert")}</a>
                        <a href='#about'>{t("header.about")}</a>
                        <a href='/sitemap.xml'>{t("header.sitemap")}</a>
                        <button className='picker' onClick={() => openLangMenu()}>
                            <img src={icon}/>
                            {menu && (
                                <ul className='languages'>
                                    <a href={`/en/${link}`}><img src={en}/></a>
                                    <a href={`/uk/${link}`}><img src={uk}/></a>
                                    <a href={`/ar/${link}`}><img src={ar}/></a>
                                    <a href={`/de/${link}`}><img src={de}/></a>
                                    <a href={`/es/${link}`}><img src={es}/></a>
                                    <a href={`/fr/${link}`}><img src={fr}/></a>
                                    <a href={`/hi/${link}`}><img src={hi}/></a>
                                    <a href={`/id/${link}`}><img src={id}/></a>
                                    <a href={`/it/${link}`}><img src={it}/></a>
                                    <a href={`/ja/${link}`}><img src={ja}/></a>
                                    <a href={`/pt/${link}`}><img src={pt}/></a>
                                    <a href={`/ru/${link}`}><img src={ru}/></a>
                                    <a href={`/zh/${link}`}><img src={zh}/></a>
                                </ul>)}</button>

                    </nav>
                </header>
                <main>
                    <div className='search_engine' id='convert'>
                        <br/>

                        <h1>
                            <Trans i18nKey="heading_text" components={{1: <orange/>}}/>

                        </h1>

                        <br/>

                        <div className="switch">
                            <button
                                className={searchMode === 'keyword' ? 'keyword_active' : 'keyword'}
                                onClick={() => handleModeSwitch('keyword')}
                            >
                                {t("search.keyword")}
                            </button>
                            <button
                                className={searchMode === 'url' ? 'url_active' : 'url'}
                                onClick={() => handleModeSwitch('url')}
                            >
                                {t("search.paste_video_url")}
                            </button>
                        </div>

                        <br/>

                        <form onSubmit={handleSearch} className="search_form">
                            <input
                                type="text"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder={searchMode === 'keyword' ? t("search.enter_search_term") : t("search.paste_yt_url")}
                            />
                            <br/>
                            <button
                                type="submit"
                                disabled={loadingSearch || !query} // Disable button when loading or query is empty
                                className={query ? 'search-button active' : 'search-button'}
                            >
                                {loadingSearch ? '...' : t("search.search")}
                            </button>
                        </form>

                        <br/>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        {loadingSearch && <p>Loading...</p>}

                    </div>

                    <br/>

                    <div className="video-list">
                        {videos.map((video) => (
                            <div key={video.id.videoId || video.id} className="video-card">
                                <div className='duration_content'>
                                    <img src={video.snippet.thumbnails.medium.url} alt="Video thumbnail"/>

                                    <h3>{decodeHtml(video.snippet.title).length > 40
                                        ? decodeHtml(video.snippet.title).slice(0, 40) + '...'
                                        : decodeHtml(video.snippet.title)}
                                    </h3>
                                    <p>Duration: {video.duration}</p>
                                </div>
                                <div className='card-buttons'>
                                    <button className='download_mp3'
                                            onClick={() => handleDownload(video.id.videoId || video.id)}
                                            disabled={loadingDownload && currentVideo === (video.id.videoId || video.id)}>
                                        {loadingDownload && currentVideo === (video.id.videoId || video.id) ? 'Downloading...' : 'Download MP3'}
                                    </button>
                                    <a className='watch_on_youtube'
                                       href={`https://www.youtube.com/watch?v=${video.id.videoId || video.id}`}
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        Watch on YouTube
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                    <br/>

                    {videos.length > 0 && nextPageToken && (
                        <button className='load_more' onClick={handleLoadMore} disabled={loadingSearch}>
                            {loadingSearch ? 'Loading...' : 'Load more videos'}
                        </button>
                    )}
                    <br/>
                    <div id='about'>
                        <br/>
                        <h1>
                            <Trans i18nKey="about" components={{1: <orange/>}}/>

                        </h1>
                        <br/>
                        <div className='content'>
                            <Example/>
                        </div>
                    </div>
                    <br/>

                </main>
                {videos.length < 4 && (
                    <div className='take_space'></div>
                )}

                <footer className="app-footer"><Menu link={link} lang={icon}/>
                    <p>© 2024 YouTube MP3. All rights reserved.</p>
                </footer>

            </div>
        </>
    )
}

export default Id