import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './index.css';
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import Example from "./locales/example";
import i18n, {resources} from "./locales/i18n";
import {Trans, useTranslation} from "react-i18next";
import {HelmetProvider} from "react-helmet-async";
import en from "./locales/icons/en.svg"
import uk from "./locales/icons/uk.svg"
import ar from "./locales/icons/ar.svg"
import de from "./locales/icons/de.svg"
import es from "./locales/icons/es.svg"
import fr from "./locales/icons/fr.svg"
import hi from "./locales/icons/hi.svg"
import id from "./locales/icons/id.svg"
import it from "./locales/icons/it.svg"
import ja from "./locales/icons/ja.svg"
import pt from "./locales/icons/pt.svg"
import ru from "./locales/icons/ru.svg"
import zh from "./locales/icons/zh.svg"
import En from "./pages/en";
import Ar from "./pages/ar";
import De from "./pages/de";
import Es from "./pages/es";
import Fr from "./pages/fr";
import Hi from "./pages/hi";
import Id from "./pages/id";
import It from "./pages/it";
import Ja from "./pages/ja";
import Pt from "./pages/pt";
import Rus from "./pages/ru";
import Uk from "./pages/uk";
import Zh from "./pages/zh";

const YOUTUBE_API_KEY = 'AIzaSyAHpzFA_2zvoejbxNiiB_qQaPMwdljjcS4';
const RAPIDAPI_KEY = '66ae7dd596msha4e88745aaa94bap1a328cjsn7d924642bacb';

const generateLink = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let link = '';
    for (let i = 0; i < 5; i++) {
        link += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return link;
};

// Sitemap Component
function Sitemap() {
    return (
        <iframe
            title="Sitemap"
            src="../public/sitemap.xml"
            style={{width: '100vw', height: '100vh', border: 'none', background: 'white', color: 'black'}}
        />
    );
};

function App() {
    const initialActiveLinks = JSON.parse(localStorage.getItem('activeLinks')) || [generateLink(), generateLink(), generateLink()];
    const initialOldLinks = JSON.parse(localStorage.getItem('oldLinks')) || [];
    const [activeLinks, setActiveLinks] = useState(initialActiveLinks);
    const [oldLinks, setOldLinks] = useState(initialOldLinks);

    useEffect(() => {
        localStorage.setItem('activeLinks', JSON.stringify(activeLinks));
        localStorage.setItem('oldLinks', JSON.stringify(oldLinks));
    }, [activeLinks, oldLinks]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newLinks = [...activeLinks];
            const oldLink = newLinks.shift();
            newLinks.push(generateLink());
            setActiveLinks(newLinks);
            setOldLinks([...oldLinks, oldLink]);
        }, 604800000); // Weekly update
        return () => clearInterval(interval);
    }, [activeLinks, oldLinks]);

    // Redirect to a random active link
    const getRandomActiveLink = () => {
        const randomIndex = Math.floor(Math.random() * activeLinks.length);
        return `/en/${activeLinks[randomIndex]}` || "/sitemap";
    };

    return (
        <HelmetProvider>
            <Router>
                <Routes>
                    {/*<Route path="/sitemap" component={Sitemap} />*/}
                    <Route path="/sitemap" component={
                        <div className='sitemap'>
                            <Sitemap/>
                        </div>}/>

                    <Route path="/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/en/${link}`} element={
                            <En links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/en/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/en/${link}`} element={
                            <En links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/ar/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/ar/${link}`} element={
                            <Ar links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/de/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/de/${link}`} element={
                            <De links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/es/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/es/${link}`} element={
                            <Es links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/fr/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/fr/${link}`} element={
                            <Fr links={activeLinks}/>
                        }/>
                    ))}


                    <Route path="/hi/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/hi/${link}`} element={
                            <Hi links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/id/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/id/${link}`} element={
                            <Id links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/it/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/it/${link}`} element={
                            <It links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/ja/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/ja/${link}`} element={
                            <Ja links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/pt/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/pt/${link}`} element={
                            <Pt links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/ru/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/ru/${link}`} element={
                            <Rus links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/uk/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/uk/${link}`} element={
                            <Uk links={activeLinks}/>
                        }/>
                    ))}

                    <Route path="/zh/*" element={
                        <Navigate to={getRandomActiveLink()} replace/>}/>
                    {activeLinks.map((link, index) => (
                        <Route key={index} path={`/zh/${link}`} element={
                            <Zh links={activeLinks}/>
                        }/>
                    ))}


                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default App;
