import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import i18n from "./i18n";

function Example() {
    const { t, i18n } = useTranslation()

    return (
        <>
            <p>
                <Trans i18nKey="welcome_message" components={{
                    1: <a href='https://youtubemp3.co/'></a>,
                    2: <a href='https://www.youtube.com/'></a>,
                }}/>
            </p>
            <h1>{t('what_is_youtubemp3')}</h1>
            <p>{t('description')}</p>
            <h1>{t('how_it_works')}</h1>
            <p>{t('steps_intro')}</p>
            <ol>
                <li>{t('steps.step_1')}</li>
                <li>{t('steps.step_2')}</li>
                <li>{t('steps.step_3')}</li>
                <li>{t('steps.step_4')}</li>
            </ol>
            <p>{t('no_sign_up')}</p>
            <h1>{t('why_convert')}</h1>
            <p>{t('reasons_intro')}</p>
            <ul>
                <li><Trans i18nKey="reasons.music_on_the_go" components={{1: <b></b>}}/></li>
                <li><Trans i18nKey="reasons.podcasts_speeches" components={{1: <b></b>}}/></li>
                <li><Trans i18nKey="reasons.background_listening" components={{1: <b></b>}}/></li>
            </ul>
            <p>
                <Trans i18nKey="fun_fact_youtube" components={{
                    1: <b></b>,
                    2: <a href='https://www.statista.com/topics/2019/youtube/'></a>,
                    3: <a href='https://tubestats.io/'></a>
                }}/>
            </p>
            <h1>{t('why_use')}</h1>
            <ul>
                <li><Trans i18nKey="advantages.free" components={{1: <b></b>}}/></li>
                <li><Trans i18nKey="advantages.fast_simple" components={{1: <b></b>}}/></li>
                <li><Trans i18nKey="advantages.no_ads" components={{1: <b></b>}}/></li>
                <li><Trans i18nKey="advantages.high_quality" components={{1: <b></b>}}/></li>
            </ul>
            <p>
                <Trans i18nKey="fun_fact_mp3"
                       components={{b: <b></b>, wiki: <a href='https://it.wikipedia.org/wiki/MP3'></a>}}/>
            </p>
            <h1>{t('perfect_occasion')}</h1>
            <p>{t('various_occasions')}</p>
            <h1>{t('is_it_legal')}</h1>
            <p><Trans i18nKey="legal_info"
                      components={{b: <b></b>, terms: <a href='https://www.youtube.com/static?template=terms'></a>}}/></p>
            <p>{t('closing_statement')}</p>
            <p>{t('call_to_action')}</p>
        </>
    );
}

export default Example;
