import './menu.css'
import React, {useEffect, useState} from "react";
import en from "../locales/icons/en.svg";
import uk from "../locales/icons/uk.svg";
import ar from "../locales/icons/ar.svg";
import de from "../locales/icons/de.svg";
import es from "../locales/icons/es.svg";
import fr from "../locales/icons/fr.svg";
import hi from "../locales/icons/hi.svg";
import id from "../locales/icons/id.svg";
import it from "../locales/icons/it.svg";
import ja from "../locales/icons/ja.svg";
import pt from "../locales/icons/pt.svg";
import ru from "../locales/icons/ru.svg";
import zh from "../locales/icons/zh.svg";
import {useTranslation} from "react-i18next";

function Menu({link, lang}){
    const [menu, setMenu] = useState(false)
    const [icon, setIcon] = useState()
    const {t, i18n} = useTranslation()
    const openLangMenu = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        setIcon(lang)
    }, [i18n]);

    return(
        <>
            <button className='second-picker' onClick={() => openLangMenu()}>
                <img src={icon}/>
                {menu && (
                    <ul className='second-languages'>
                        <a href={`/en/${link}`}><img src={en}/></a>
                        <a href={`/uk/${link}`}><img src={uk}/></a>
                        <a href={`/ar/${link}`}><img src={ar}/></a>
                        <a href={`/de/${link}`}><img src={de}/></a>
                        <a href={`/es/${link}`}><img src={es}/></a>
                        <a href={`/fr/${link}`}><img src={fr}/></a>
                        <a href={`/hi/${link}`}><img src={hi}/></a>
                        <a href={`/id/${link}`}><img src={id}/></a>
                        <a href={`/it/${link}`}><img src={it}/></a>
                        <a href={`/ja/${link}`}><img src={ja}/></a>
                        <a href={`/pt/${link}`}><img src={pt}/></a>
                        <a href={`/ru/${link}`}><img src={ru}/></a>
                        <a href={`/zh/${link}`}><img src={zh}/></a>
                    </ul>)}</button>
        </>
    )
}

export default Menu;